<template>
    <div class="body">
        <!-- banner区域 Start -->
        <div class="banner">
            <m-top-bar></m-top-bar>
        </div>
        <!-- banner区域 End -->

        <!-- switchBar区域 Start -->
        <div class="switchBar">
            <div class="switchBox">智能无线保管柜</div>
        </div>
        <div class="line"></div>
        <!-- switchBar区域 End -->

        <!-- container区域 Start -->
        <div class="container">
            <div class="containerBox">
                <img src="../../../../assets/mobile/img/product/cabinet.png" alt="" class="pic">
                <div class="title">智能无线保管柜</div>
                <div class="text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;HLD智能保管柜系列产品是宁讯推出基于模块化设计，运用RFID射频技术自动控制技术、工业网络技术、互联网技术系统设计的智能柜产品系列。HLD智能保管柜基于超高频RFID扫描系统，物资识别率达到99.9%，扫描速度1～10S可调。可根据客户需求进行灵活定制。
                </div>
                <div class="title">应用场景</div>
                <div class="text">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;HLD智能保管柜可广泛应用于电力、医疗、智能工厂等仓库领域。对相关物资工具产品可实现智能化、数字化、无人化管理
                </div>
            </div>
        </div>
        <!-- container区域 End -->

        <!-- baseBar区域 Start -->
        <m-base-bar></m-base-bar>
        <!-- baseBar区域 End -->
    </div>
</template>
    
<script>
import mTopBar from '@/components/mTopBar.vue'
import mBaseBar from '@/components/mBaseBar.vue'

export default {
    name: 'Support',
    components: {
        mTopBar,
        mBaseBar,
    }
}
</script>
  
<style scoped>
.body {
    background-color: #f7f7f7 !important;
}

/* banner区域 */
.banner {
    width: 100%;
    min-height: 2050px;
    background: url(../../../../assets/mobile/img/solution/banner.jpg) center no-repeat;
    background-size: 335%;
}

/* switchBar区域 */
.switchBar {
    padding-top: 50px;
    width: 100%;
    height: 300px;
    text-align: center;
    background-color: #ffffff;
}

.switchBar .switchBox {
    margin: 0 auto;
    text-align: center;
    width: 700px;
    height: 200px;
    font-size: 72px;
    line-height: 200px;
    color: #ffffff;
    border-radius: 30px;
    background-color: #c3a35d;
}

.line {
    margin: 0 auto;
    width: 90%;
    border-bottom: 1px solid #c3a35d;
}

/* container区域 */
.container {
    padding: 50px;
    width: 100%;
    min-height: 500px;
}

.container .containerBox {
    padding: 100px;
    width: 100%;
    min-height: 300px;
    text-align: justify;
    background-color: #ffffff;
}

.container .containerBox .pic {
    margin-left: 220px;
    margin-bottom: 150px;
    width: 1200px;
}

.container .containerBox .title {
    margin-bottom: 50px;
    font-weight: 700;
    font-size: 80px;
}

.container .containerBox .text {
    margin-bottom: 50px;
    font-size: 70px;
    color: #999;
}
</style>